



















































































































import {Vue, Component} from 'vue-property-decorator'

@Component
export default class HomepageCardContent extends Vue {}
