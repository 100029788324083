

























































































import moment from 'moment'
import Parser from 'rss-parser'
import {Component, Mixins} from 'vue-property-decorator'
import LatestNewsCard from '@/components/cards/LatestNewsCard.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import Swiper from 'swiper'
import {SwiperSlide} from 'vue-awesome-swiper'
import {LatestNewsItem} from '@/model/resource/LatestNewsItem'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {AppFooter, NavbarMenu, LatestNewsCard, SwiperSlide},
  filters: {
    moment: (value: string) => moment(value).format('Do MMM YYYY'),
  },
})
export default class LatestNewsView extends Mixins(MixinScreenSize) {
  isEmpty = true
  isBeginning = true
  isEnd = true

  news: LatestNewsItem[] = []
  allNewsURL: string = ''

  //@ts-ignore
  swiper: Swiper

  get swiperOption() {
    return {
      pagination: {
        el: '.last-news-swiper-pagination',
        clickable: true,
      },
      slidesPerView: 3,
      slidesPerGroup: 3,
    }
  }

  mounted() {
    this.$await.run('listLatestNewsItem', () => this.populate())
  }

  async populate() {
    const {data} = await LatestNewsItem.listLatestNewsItem()
    if (data) this.isEmpty = false

    const feed = await new Parser().parseString(data as string)
    this.allNewsURL = feed.link || ''

    const itemList = feed.items as LatestNewsItem[]

    itemList.forEach(item => {
      const content = document.createElement('div')
      content.innerHTML = item.content || ''

      const children = Array.from(content.children)

      item.img = (children.shift() || {}).outerHTML || ''
      item.description = children.map(child => child.outerHTML)
    })

    this.news = itemList.slice(0, this.isMobile ? 3 : 12)

    //@ts-ignore
    this.swiper = this.$refs.swiper.$swiper

    this.updateEvent()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
