











































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import NetworkTag from '@/components/NetworkTag.vue'

@Component({
  components: {NetworkTag, PercentageProgress},
})
export default class ExploreDappsCard extends Mixins(MixinScreenSize) {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean, default: true}) showPercentage?: boolean
}
