/**
 * Banner
 * @author Simpli CLI generator
 */
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {BannerCollection} from '@/model/collection/BannerCollection'
import {Global, IBannerAction} from '@/model/resource/Global'

/* TODO: review generated class */
export class Banner implements IResource {
  idBannerPk: number = 0

  imageUrl: string | null = null
  url: string | null = null
  active: boolean | null = null
  order: number | null = null

  title: string | null = null
  type: string | null = null
  description: string | null = null
  tags: string | null = null
  visibleStartDatetime: string | null = null
  visibleEndDatetime: string | null = null
  websiteSection: string | null = null
  action: string | null = null

  get $id() {
    return this.idBannerPk
  }

  set $id(val) {
    this.idBannerPk = val
  }

  get $tag() {
    return String(this.title)
  }

  get tagNames() {
    if (!this.tags) return []

    const names = this.tags.split(',')
    return names.filter((item, i) => names.indexOf(item) == i).slice(0, 5)
  }

  get websiteSectionAsIResource(): IResource | null {
    return (
      Global.bannerWebsiteSections.find(
        (item, i) => item.$tag === this.websiteSection
      ) ?? null
    )
  }

  set websiteSectionAsIResource(val: IResource | null) {
    this.websiteSection = val?.$tag ?? null
  }

  get actionAsIBannerAction(): IBannerAction | null {
    return (
      Global.bannerActions.find((item, i) => item.name === this.action) ?? null
    )
  }

  /**
   * Gets a instance of a given ID of Banner
   */
  async getBanner(id: number) {
    return await Request.get(`/client/banner/${id}`)
      .name('getBanner')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Banner
   */
  async removeBanner() {
    return await Request.delete(`/client/banner/${this.$id}`)
      .name('removeBanner')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Banner
   */
  static async listBanner(params: any) {
    return await Request.get(`/client/banner`, {params})
      .name('listBanner')
      .as(BannerCollection)
      .getData()
  }

  /**
   * Persists a new instance of Banner. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistBanner() {
    return await Request.post(`/client/banner`, this)
      .name('persistBanner')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Banner to export as a file
   */
  static async listExportBanner(params: any) {
    return await Request.get(`/client/banner/export`, {params})
      .name('listExportBanner')
      .as(BannerCollection)
      .getData()
  }

  /**
   * Increments the order of banner
   */
  async changeOrder() {
    return await Request.post(`/client/banner/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
