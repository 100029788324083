var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gas-calculator-view"},[_c('div',{staticClass:"horiz mb-2 md:mb-10 lg:mb-20 justify-center sm:justify-start"},[_c('h1',{staticClass:"gas-calculator-view__title",class:_vm.titleClass},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.title'))+" ")]),_c('img',{attrs:{"src":require("@/assets/img/calculator.svg"),"width":"30","alt":"gas calculator icon"}})]),_c('await',{attrs:{"name":"softQuery"}},[_c('div',{staticClass:"bg-white shadow-lg rounded-lg py-6 px-4 md:p-16 weight-1"},[_c('div',{staticClass:"lg:horiz border-b md:border-none"},[_c('div',{staticClass:"horiz items-center my-2 mx-2"},[_c('span',{staticClass:"gas-calculator-view__content-text"},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.main.first'))+" ")]),_c('div',{staticClass:"horiz lg:verti items-center text-center"},[_c('div',{staticClass:"gas-calculator-view__content-neo-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qtdNeoVoting),expression:"qtdNeoVoting"}],staticClass:"w-6/12 text-base sm:text-2xl md:text-3xl text-right text-bright-gray outline-none bg-transparent",attrs:{"inputmode":"numeric"},domProps:{"value":(_vm.qtdNeoVoting)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.qtdNeoVoting=$event.target.value},_vm.onChangeInputValue,_vm.calculateVote]}}),_c('span',{staticClass:"gas-calculator-view__content-divisor"},[_vm._v(" | ")]),_c('div',{staticClass:"horiz items-center"},[_c('img',{attrs:{"src":require("@/assets/img/neo.svg"),"width":_vm.isMobile ? '16' : '25',"alt":"neo icon"}}),_c('span',{staticClass:"gas-calculator-view__content-symbol"},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.main.neo'))+" ")])])]),_c('p',{staticClass:"gas-calculator-view__content-currency",attrs:{"title":_vm.neoCostTotalInDollar.toLocaleString('en-US', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 0,
                  style: 'currency',
                  currency: 'USD',
                })}},[_vm._v(" "+_vm._s(_vm.$t('country.USA.alpha2'))+" "+_vm._s(_vm.neoCostTotalInDollar.toLocaleString('en-US', { maximumFractionDigits: 3, minimumFractionDigits: 0, style: 'currency', currency: 'USD', }))+" ")])])]),_c('div',{staticClass:"horiz items-center my-6 mx-2"},[_c('span',{staticClass:"gas-calculator-view__content-text"},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.main.second'))+" ")]),_c('div',{staticClass:"horiz lg:verti items-center text-center"},[_c('div',{staticClass:"gas-calculator-view__content-gas-box"},[_c('p',{staticClass:"w-4/12 lg:w-6/12 text-right text-base sm:text-2xl md:text-3xl lg:text-4xl font-bold text-ocean-green truncate",attrs:{"title":this.qtdGasToClaim}},[_vm._v(" "+_vm._s(this.qtdGasToClaim.toLocaleString('en-US', { maximumFractionDigits: 3, minimumFractionDigits: 3, }))+" ")]),_c('span',{staticClass:"gas-calculator-view__content-divisor"},[_vm._v("|")]),_c('div',{staticClass:"flex items-center-center"},[_c('img',{attrs:{"src":require("@/assets/img/gas.svg"),"width":_vm.isMobile ? '16' : '25',"alt":"gas icon"}}),_c('span',{staticClass:"gas-calculator-view__content-symbol"},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.main.gas'))+" ")])])]),_c('p',{staticClass:"gas-calculator-view__content-currency",attrs:{"title":_vm.gasCostTotalInDollar.toLocaleString('en-US', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 0,
                  style: 'currency',
                  currency: 'USD',
                })}},[_vm._v(" "+_vm._s(_vm.$t('country.USA.alpha2'))+" "+_vm._s(_vm.gasCostTotalInDollar.toLocaleString('en-US', { maximumFractionDigits: 3, minimumFractionDigits: 0, style: 'currency', currency: 'USD', }))+" ")])]),(!(_vm.isMobile || _vm.isTablet || _vm.isSmallDesktop))?_c('span',{staticClass:"gas-calculator-view__content-text"},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.main.third'))+" ")]):_vm._e()])]),_c('div',{staticClass:"p-4 xl:p-6 mt-4 bg-pearl-aqua bg-opacity-25 rounded-lg"},[_c('span',{staticClass:"text-sm sm:text-base md:text-lg lg:text-xl text-bright-gray font-medium"},[_vm._v(" "+_vm._s(!(_vm.isMobile || _vm.isTablet || _vm.isSmallDesktop) ? _vm.$t('view.home.gasCalculator.descriptionLargeDesktop') : _vm.$t('view.home.gasCalculator.description'))+" ")])]),(!(_vm.isMobile || _vm.isTablet || _vm.isSmallDesktop))?_c('div',{staticClass:"horiz mb-6 justify-end xl:border-t mt-6"},[_c('router-link',{staticClass:"btn btn--contrast bg-black h-10 text-lg font-medium mt-8",attrs:{"to":{name: 'advancedGasCalculator'}}},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.viewAdvancedGasCalculator'))+" "),_c('em',{staticClass:"ml-2 text-2xl text-aquamarine fas fa-angle-right"})])],1):_vm._e()]),(_vm.isMobile || _vm.isTablet || _vm.isSmallDesktop)?_c('div',{staticClass:"horiz justify-center md:border-t mt-6"},[_c('router-link',{staticClass:"btn btn--contrast bg-black h-10 text-lg font-medium mt-8",attrs:{"to":{name: 'advancedGasCalculator'}}},[_vm._v(" "+_vm._s(_vm.$t('view.home.gasCalculator.viewAdvancedGasCalculator'))+" "),_c('em',{staticClass:"ml-2 text-2xl text-aquamarine fas fa-angle-right"})])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }