/**
 * GasCalculatorTarget
 * @author Lopes
 */
import {IResource} from '@simpli/resource-collection'

export class GasCalculatorTarget implements IResource {
  scriptHash: string | null = null
  name: string | null = null
  publicKey: string | null = null
  numberOfVotes: number | null = null
  active: boolean | null = null
  dailyGasRate: string | null = null
  apr: string | null = null
  isCandidate: boolean | null = null
  imageUrl: string | null = null
  createdAt: Date | null = null
  updatedAt: Date | null = null
  dailyGasRateUpdatedTime: Date | null = null
  order: number | null = null

  get $id() {
    return 0
  }

  get $tag() {
    return `${this.scriptHash}`
  }
}
