











































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class GasbotAbout extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, default: false}) showImageLeftSide!: boolean
  @Prop({type: Boolean, default: false}) showImageTopSide!: boolean
  @Prop({type: Boolean, default: false}) showAltImageTopSide!: boolean
  @Prop({type: Boolean, default: true}) showImageRightSide!: boolean
  @Prop({type: Boolean, default: true}) showDescription!: boolean
  @Prop({type: Boolean, default: false}) showLearnMoreButton!: boolean
  @Prop({type: String, default: 'text-2xl md:text-4xl mb-2 md:mb-8'})
  titleClass!: string
  @Prop({type: String, default: 'p-5 md:p-12'}) contentClass!: string
  @Prop({type: String, default: 'mt-6 md:mt-12 text-sm md:text-xl'})
  descriptionsClass!: string
  @Prop({type: String, default: 'w-10 mr-5 mb-2 md:mb-8'}) iconClass!: string
  @Prop({type: String, required: true}) titleContent!: string
  @Prop({type: String, required: true}) shortDescriptionContent!: string

  formatText(text: String): String {
    return text.replace('\n', '<br><br>')
  }
}
