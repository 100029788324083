
















































import {Component, Prop} from 'vue-property-decorator'
import Swiper from 'swiper'
import moment from 'moment'
import {BannerCollection} from '@/model/collection/BannerCollection'
import BannerCard from '@/components/cards/BannerCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {BannerCard},
})
export default class BannerList extends MixinScreenSize {
  @Prop({type: String, required: true}) page!: string
  collection = new BannerCollection()

  isBeginning = true
  isEnd = true

  swiper!: Swiper

  get swiperOption() {
    return {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 24,
      pagination: {
        el: '.banner-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        [this.$env.LARGE_SCREEN]: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
      },
    }
  }

  async mounted() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.perPage = null
    this.collection.orderBy = 'order'
    this.collection.websiteSection = this.page
    this.collection.endVisibleStartDatetime = moment().format()
    this.collection.startVisibleEndDatetime = moment().format()
  }

  async populateResources() {
    await this.$await.run('loadBanner', () => this.collection.expand())
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
    this.updateSwiper()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  updateSwiper() {
    if (this.isMobile) {
      this.swiper.autoplay.start()
    } else {
      this.swiper.autoplay.stop()
    }
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
