import {IResource} from '@simpli/resource-collection'

export class TokenPriceSource implements IResource {
  idTokenPriceSourcePk: number = 0

  name: string | null = null

  get $id() {
    return this.idTokenPriceSourcePk
  }
  set $id(val) {
    this.idTokenPriceSourcePk = val
  }
  get $tag() {
    return String(this.$id)
  }
}
