/**
 * ContractNep17Collection
 * @author Lopes
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {PriceHistory} from '@/model/resource/PriceHistory'

@HttpExclude()
export class PriceHistoryCollection extends PageCollection<PriceHistory> {
  @RequestExpose() symbols: string[] | null = null

  @RequestExpose() withoutTotal: boolean | null = null

  resource?: IPriceHistoryCollectionResourcesHolder

  constructor() {
    super(PriceHistory)
  }

  queryAsPage() {
    return this.listPriceHistory()
  }

  async listPriceHistory() {
    return await Request.get(`client/price-history`, {
      params: this.params,
    })
      .name('listPriceHistory')
      .as(this)
      .getResponse()
  }
}

export interface IPriceHistoryCollectionResourcesHolder {}
