
































































import {Vue, Component, Prop} from 'vue-property-decorator'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'
import moment from 'moment/moment'
import {BlockchainHourlyCache} from '@/model/resource/BlockchainHourlyCache'

type NeoXStatistics = {
  title: string
  subtitle: string
  statistics: Statistics[]
}

type Statistics = {
  title: string
  value: string
  weekTitle: string
  weekValue: number
}

@Component({
  components: {HomepageCardContent, PercentageProgress, HomepageCardHeader},
})
export default class NeoXStatisticsCard extends Vue {
  @Prop({type: Array, required: true})
  blockchainHourlyCacheCurrentDayItems!: BlockchainHourlyCache[]
  @Prop({type: Array, required: true})
  blockchainHourlyCacheLastWeekItems!: BlockchainHourlyCache[]

  items: NeoXStatistics = {
    title: this.$translate('components.cards.NeoXStatisticsCard.title'),
    subtitle: this.$translate('components.cards.NeoXStatisticsCard.subtitle'),
    statistics: [],
  }

  private static getPercentage(total: number | null, value: number): number {
    if (!total || total === 0) return 0

    return +((value / total) * 100).toPrecision(1)
  }

  async mounted() {
    await this.getData()
  }

  getImageUrl(index: number) {
    switch (index) {
      case 0:
        return require('@/assets/img/networkContracts.svg')
      case 1:
        return require('@/assets/img/addresses.svg')
      case 2:
        return require('@/assets/img/allTimeTransactions.svg')
    }
  }

  private async getData() {
    if (
      this.blockchainHourlyCacheCurrentDayItems.length > 0 &&
      this.blockchainHourlyCacheLastWeekItems.length > 0
    ) {
      this.items.subtitle = this.$translate(
        'components.cards.NeoXStatisticsCard.subtitle',
        {
          date: moment(
            this.blockchainHourlyCacheCurrentDayItems[0].date
          ).format('ddd, HH:mm'),
        }
      )
      const hourlyCacheToday = this.blockchainHourlyCacheCurrentDayItems[0]
      const hourlyCache7DaysAgo = this.blockchainHourlyCacheLastWeekItems[0]

      const transactionCountToday = hourlyCacheToday.transactionCount ?? 0
      const transactionCount7DaysAgo = hourlyCache7DaysAgo.transactionCount ?? 0
      const tcDiffTodayWith7d = transactionCountToday - transactionCount7DaysAgo

      this.items.statistics = [
        {
          title: this.$translate(
            'components.cards.NeoXStatisticsCard.networkContracts.title'
          ),
          value: hourlyCacheToday.contractCount?.toLocaleString('eng-us') ?? '',
          weekTitle: this.$translate(
            'components.cards.NeoXStatisticsCard.networkContracts.weekTitle'
          ),
          weekValue:
            (hourlyCacheToday.contractCount || 0) -
            (hourlyCache7DaysAgo?.contractCount ?? 0),
        },
        {
          title: this.$translate(
            'components.cards.NeoXStatisticsCard.addresses.title'
          ),
          value: hourlyCacheToday.addressCount?.toLocaleString('eng-us') ?? '',
          weekTitle: this.$translate(
            'components.cards.NeoXStatisticsCard.addresses.weekTitle'
          ),
          weekValue:
            (hourlyCacheToday.addressCount || 0) -
            (hourlyCache7DaysAgo?.addressCount ?? 0),
        },
        {
          title: this.$translate(
            'components.cards.NeoXStatisticsCard.allTimeTransactions.title'
          ),
          value: transactionCountToday.toLocaleString('eng-us') ?? '',
          weekTitle: this.$translate(
            'components.cards.NeoXStatisticsCard.allTimeTransactions.weekTitle'
          ),
          weekValue: NeoXStatisticsCard.getPercentage(
            transactionCount7DaysAgo,
            tcDiffTodayWith7d
          ),
        },
      ]
    }
  }
}
