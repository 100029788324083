





















































































import Vue from 'vue'
import {TranslateResult} from 'vue-i18n'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {Dapp} from '@/model/resource/Dapp'
import {Fragment} from 'vue-fragment'
import {NftCollection} from '@/model/resource/NftCollection'
import moment from 'moment'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'

type TopNfts = {
  title: TranslateResult
  timeTitle: TranslateResult
  seeAll: TranslateResult
  createdBy: TranslateResult
  lastTimeUpdated: TranslateResult | string
  nfts: Nfts[]
}

type Nfts = {
  idNftCollectionPk: number
  name: string | null | undefined
  imageUrl: string | null | undefined
  volumeTitle: TranslateResult
  monthVolumeChange: number | null
  dataFetchedDatetime: Date | null
  dapp: Dapp | null
  ownerAddress: string | null
  slug: string | null
}

@Component({
  components: {
    HomepageCardContent,
    HomepageCardHeader,
    PercentageProgress,
    Fragment,
  },
})
export default class TopNftsCard extends Vue {
  @Prop({type: Array, required: true})
  topNftsItems!: NftCollection[]

  items: TopNfts = {
    title: this.$t('components.cards.TopNftsCard.title'),
    timeTitle: this.$t('components.cards.TopNftsCard.timeTitle'),
    seeAll: this.$t('components.cards.TopNftsCard.seeAll'),
    createdBy: this.$t('components.cards.TopNftsCard.createdBy'),
    lastTimeUpdated: '',
    nfts: [],
  }

  async mounted() {
    await this.getData()
  }

  copyToClipboard(address: string) {
    this.$utils.copyToClipboard(address)
    this.$toast.info('system.info.copiedToClipboard')
  }

  ghostmarketCollectionUrl(slug: string) {
    return `${process.env.VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL}${slug}`
  }

  formattedAddress(address: string) {
    if (!address) return undefined

    return `${address.slice(0, 4)}...${address.slice(-4)}`
  }

  private async getData() {
    if (this.topNftsItems.length > 1) {
      for (let i = 0; i < this.topNftsItems.length; i++) {
        const topNft = this.topNftsItems[i]
        this.items.nfts.push({
          idNftCollectionPk: topNft.idNftCollectionPk,
          name: topNft.name,
          ownerAddress: topNft.ownerAddress,
          dapp: topNft.dapp,
          imageUrl: topNft.imageUrl,
          volumeTitle: this.$t('components.cards.TopNftsCard.volumeTitle'),
          monthVolumeChange: topNft.monthVolumeChange,
          dataFetchedDatetime: topNft.updatedAt,
          slug: topNft.slug,
        })
      }

      this.items.lastTimeUpdated = moment
        .utc(this.topNftsItems[0].updatedAt)
        .format('ddd, HH:mm')
        .toUpperCase()
    }
  }
}
