import { render, staticRenderFns } from "./N3StatisticsCard.vue?vue&type=template&id=71f0f04f&scoped=true"
import script from "./N3StatisticsCard.vue?vue&type=script&lang=ts"
export * from "./N3StatisticsCard.vue?vue&type=script&lang=ts"
import style0 from "./N3StatisticsCard.vue?vue&type=style&index=0&id=71f0f04f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f0f04f",
  null
  
)

export default component.exports