




















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class TokenNftHeaderMenu extends Vue {
  @Prop({type: String}) selectedName!: string

  activatedClass(name: string) {
    return {
      'token-nft-nav-header-menu__btn--active': name === this.selectedName,
      'token-nft-nav-header-menu__btn': name !== this.selectedName,
    }
  }

  handleClick(name: string) {
    this.$emit('selectedTable', name)
  }
}
