





































































































































































import {Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import {InputText} from '@simpli/vue-input'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'
import {GasCalculatorTargetCollection} from '@/model/collection/GasCalculatorTargetCollection'

@Component({
  components: {InputText},
})
export default class GasCalculatorView extends Mixins(MixinScreenSize) {
  @Prop({type: String, default: 'text-title'}) titleClass!: string

  priceHistoryCollection = new PriceHistoryCollection()
  gasCalculatorTarget = new GasCalculatorTargetCollection()

  lastValue: string = '100'
  qtdNeoVoting: string = '100'

  qtdGasToClaim: number = 0
  gasCostTotalInDollar: number = 0
  gasCurrentPriceInDollar: number = 0
  neoCostTotalInDollar: number = 0
  neoCurrentPriceInDollar: number = 0

  mounted() {
    this.applyCollection()
    this.populateResources()
  }

  applyCollection() {
    this.priceHistoryCollection.noPagination()
    this.priceHistoryCollection.symbols = ['GAS', 'NEO']
    this.priceHistoryCollection.withoutTotal = true
    this.gasCalculatorTarget.withoutTotal = true
    this.gasCalculatorTarget.noPagination()
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [
      this.priceHistoryCollection.queryAsPage(),
      this.gasCalculatorTarget.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))

    const neoHistory = this.priceHistoryCollection.items.find(
      item => item.contractNep17?.symbol === 'NEO'
    )
    const gasHistory = this.priceHistoryCollection.items.find(
      item => item.contractNep17?.symbol === 'GAS'
    )

    if (neoHistory) {
      this.neoCurrentPriceInDollar = Number(neoHistory.currentPrice)
    }

    if (gasHistory) {
      this.gasCurrentPriceInDollar = Number(gasHistory.currentPrice)
    }

    this.calculateVote()
  }

  calculateVote() {
    const qtdNeoVoting = Number(this.qtdNeoVoting)

    const candidatesRewards = this.gasCalculatorTarget.items.map(
      candidate => Number(candidate.dailyGasRate) * qtdNeoVoting * 30
    )

    this.qtdGasToClaim = Math.max(...candidatesRewards)

    this.neoCostTotalInDollar = this.neoCurrentPriceInDollar * qtdNeoVoting
    this.gasCostTotalInDollar =
      this.gasCurrentPriceInDollar * this.qtdGasToClaim
  }

  onChangeInputValue(event: Event) {
    const MAX_LENGTH = 100000000

    let value

    value = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '')

    if (
      value.length - String(MAX_LENGTH).length > 1 &&
      parseInt(value) > MAX_LENGTH
    ) {
      this.qtdNeoVoting = String(MAX_LENGTH)
      this.lastValue = this.qtdNeoVoting
      return
    }

    if (parseInt(value) > MAX_LENGTH) {
      this.qtdNeoVoting = this.lastValue
      return
    }

    this.qtdNeoVoting = value
    this.lastValue = this.qtdNeoVoting
  }
}
