


























































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {Fragment} from 'vue-fragment'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'
import NetworkTag from '@/components/NetworkTag.vue'
import {Dapp} from '@/model/resource/Dapp'

@Component({
  components: {
    NetworkTag,
    HomepageCardContent,
    HomepageCardHeader,
    PercentageProgress,
    Fragment,
  },
})
export default class TopDappsCard extends Vue {
  @Prop({type: Array, required: true}) dapps!: Dapp[]
}
