










































import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class HomepageCardHeader extends Vue {
  @Prop({type: String, default: ''}) seeAllRedirect!: string
}
